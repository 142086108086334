import { paths } from '@luxuryescapes/contract-svc-lux-loyalty';
import { Order } from '@luxuryescapes/contract-svc-order';

import { BASE_PATH } from '~/services/LuxLoyaltyService';
import { CreditPaymentsWithSource, getCreditPaymentsWithSource } from '~/services/PaymentsService';
import { request } from '~/services/common';

import { Payment } from '~/types/services/payment';

import { isLuxLoyaltyEnabled } from '~/utils/luxLoyalty';

type AdminLuxLoyaltyEarnRequestPayload = paths['/api/lux-loyalty/admin/earn']['post']['parameters']['body']['payload'];

type AdminLuxLoyaltyEarnResponse =
  paths['/api/lux-loyalty/admin/earn']['post']['responses']['201']['content']['application/json'];

type AdminLuxLoyaltyGetAccrualsByOrderResponse =
  paths['/api/lux-loyalty/admin/earn/order/{orderId}']['get']['responses']['200']['content']['application/json'];

export type LuxLoyaltyAccrual = AdminLuxLoyaltyGetAccrualsByOrderResponse['result']['accruals'];

export type LuxLoyaltyEarnEligibleOrderItem =
  | App.OrderItem
  | Order.BedbankItem
  | App.OrderTourItem
  | App.ExperienceItem
  | Order.CruiseItem
  | App.FlightItem
  | Order.SubscriptionItem
  | App.CarHireItem
  | App.InsuranceItem
  | App.OptionalExperienceItem;

export type AccrualOp = 'earn' | 'estimate' | 'force-earn';

/*

 THE BELOW IS TO BE USED IN STAGING ONLY

 The implementation for earn in the production environment relies on asynchronous jobs processing data from BigQuery.

 As such, in the staging environment we are not currently able to process earn in the normal way.

 This component of the LuxLoyalty service has been separated due to its temporary nature. The endpoints in `svc-lux-loyalty` are available only in staging and dev environments.

 **/

const itemKeys: Array<keyof App.Order> = [
  'accommodation_items',
  'addon_items',
  'booking_protection_items',
  'bedbank_items',
  'business_credit_items',
  'car_hire_items',
  'cruise_items',
  'custom_offer_items',
  'experience_items',
  'flight_items',
  'gift_card_items',
  'insurance_items',
  'offline_flight_items',
  'tour_items',
  'subscription_items',
  'tour_optional_experience_items',
];

function sumPromoCodeDiscounts(order: App.Order): number {
  let discountTotal = 0;

  for (const key in itemKeys) {
    (order[key] ?? []).forEach((item: { promo_code_discount_amount: number; status: string }) => {
      discountTotal +=
        item.promo_code_discount_amount && item.status === 'completed' ? item.promo_code_discount_amount : 0;
    });
  }

  return discountTotal;
}

function sumOrderTotals(order: App.Order): number {
  let total = 0;
  for (const key in itemKeys) {
    (order[key] ?? []).forEach((item) => {
      total += item.total && item.status === 'completed' ? item.total : 0;
    });
  }
  return total;
}

/**
 * @deprecated
 */
function allOrderEarnDetails(
  order: App.Order,
  payments: Array<Payment>,
  creditsSource: CreditPaymentsWithSource,
): Array<AdminLuxLoyaltyEarnRequestPayload['orderEarnDetails'][number]> {
  const earnDetails = [];

  itemKeys.forEach((key) => {
    (order[key] ?? []).forEach((item) => {
      if (item.status === 'completed' && !!item?.loyalty_offer_type) {
        earnDetails.push(constructManualLoyaltyEarnItemPayload(order, item, payments, creditsSource));
      }
    });
  });

  return earnDetails;
}

type LuxLoyaltyEarnProductType = AdminLuxLoyaltyEarnRequestPayload['orderEarnDetails'][number]['loyalty_offer_type'];

/**
 * @deprecated
 */
function constructManualLoyaltyEarnItemPayload(
  order: App.Order,
  item: LuxLoyaltyEarnEligibleOrderItem,
  payments: Array<Payment>,
  creditsSource: CreditPaymentsWithSource,
): AdminLuxLoyaltyEarnRequestPayload['orderEarnDetails'][number] {
  let offer_bk: string | null = null;
  let package_bk: string | null = null;
  let check_in_date: string | null = null;
  let check_out_date: string | null = null;

  let lux_plus_price = null;
  if (itemTypeGuard(item)) {
    if (item.type === 'accommodation') {
      const accommodationItem = item as App.OrderItem;
      lux_plus_price = accommodationItem.offer_package?.lux_plus_price;
      offer_bk = accommodationItem.offer?.salesforce_record_type;
      package_bk = accommodationItem.offer_package?.le_package_id;
      check_in_date = new Date(accommodationItem.reservation.check_in).toISOString();
      check_out_date = new Date(accommodationItem.reservation.check_out).toISOString();
    }
    if (item.type === 'bedbank') {
      const bedbankItem = item as Order.BedbankItem;
      check_in_date = new Date(bedbankItem.check_in).toISOString();
      check_out_date = new Date(bedbankItem.check_out).toISOString();
    }
    if (item.type === 'tour') {
      const tourItem = item as App.OrderTourItem;
      check_in_date = new Date(tourItem.start_date).toISOString();
      check_out_date = new Date(tourItem.end_date).toISOString();
    }
    if (item.type === 'cruise') {
      const cruiseItem = item as Order.CruiseItem;
      check_in_date = cruiseItem.departure_date;
      check_out_date = cruiseItem.arrival_date;
    }
  }

  if (!lux_plus_price) {
    lux_plus_price = luxPlusPriceGuard(item) && item.lux_plus_price ? Number(item.lux_plus_price) : 0;
  }

  const credits_origin = creditsSource.map((c) => JSON.stringify(c.source));

  return {
    loyalty_offer_type: item.loyalty_offer_type.toLowerCase() as LuxLoyaltyEarnProductType,
    product_bk: 'test',
    customer_bk: order.fk_customer_id,
    purchase_date: new Date().toISOString(),
    offer_bk: offer_bk ?? null,
    package_bk: package_bk ?? null,
    gross_sales_aud: Number(item.total),
    luxplus_member_price_aud: lux_plus_price,
    adjusted_luxplus_member_price_aud: lux_plus_price,
    adjusted_gross_sales_aud: Number(item.total),
    promo_code_discount_amount_aud: promoCodeDiscountGuard(item) ? item.promo_code_discount_amount ?? 0 : 0,
    /**
     * @notice We cannot trust the order total for orders where there is a refund
     */
    total_order_value_aud: order.total,
    total_order_promo_discount_aud: sumPromoCodeDiscounts(order),
    country_code_2: 'AU',
    brand_bk: 'luxuryescapes',
    item_status: 'completed',
    // This is accurate, we unnest a nested array in BQ which resolves to an array of strings
    payment_info: payments
      .filter((p) => !!p.items_breakdown)
      .map((p) => p.items_breakdown)
      .flat()
      .map((item_breakdown) => JSON.stringify(item_breakdown)),
    credits_origin,
    item_bk: item.id,
    order_bk: order.id,
    check_in_date,
    check_out_date,
    transaction_type: 'order_item',
    lux_loyalty_member_tier: order.lux_loyalty_tier ?? null,
    /**
     * @notice We're faking the points earn date so we don't get an error from svc-lux-loyalty
     */
    points_earn_date: new Date().toISOString(),
    mx: mxGuard(item) ? item.mx : null,
  };
}

function luxPlusPriceGuard(
  item: LuxLoyaltyEarnEligibleOrderItem,
): item is LuxLoyaltyEarnEligibleOrderItem & { lux_plus_price: number | null } {
  return 'lux_plus_price' in item;
}

function promoCodeDiscountGuard(
  item: LuxLoyaltyEarnEligibleOrderItem,
): item is LuxLoyaltyEarnEligibleOrderItem & { promo_code_discount_amount: number | null } {
  return 'promo_code_discount_amount' in item;
}

function mxGuard(item: LuxLoyaltyEarnEligibleOrderItem): item is LuxLoyaltyEarnEligibleOrderItem & { mx?: string } {
  return 'mx' in item;
}

function itemTypeGuard(
  item: LuxLoyaltyEarnEligibleOrderItem,
): item is LuxLoyaltyEarnEligibleOrderItem & { type: string } {
  return 'type' in item;
}

async function postAdminLuxLoyaltyEarn(
  payload: AdminLuxLoyaltyEarnRequestPayload,
): Promise<AdminLuxLoyaltyEarnResponse['result']> {
  const uri = `${BASE_PATH}/admin/earn`;

  const body = JSON.stringify(payload);

  const response: AdminLuxLoyaltyEarnResponse = await request(uri, {
    method: 'POST',
    body,
    headers: { 'Content-Type': 'application/json' },
  });

  return response.result;
}

/**
 * @deprecated
 */
export async function manualEarnLuxLoyaltyPoints(
  order: App.Order,
  item: LuxLoyaltyEarnEligibleOrderItem,
  payments: Array<Payment>,
  op: AccrualOp,
): Promise<AdminLuxLoyaltyEarnResponse['result']> {
  if (!isLuxLoyaltyEnabled() || item.status !== 'completed') {
    return null;
  }
  const creditsWithSource = await getCreditPaymentsWithSource(order.id);

  // need all completed order items for numNights testing
  const orderEarnDetails = allOrderEarnDetails(order, payments, creditsWithSource.result.creditPayments);

  const payload: AdminLuxLoyaltyEarnRequestPayload = {
    earnItemId: item.id,
    op,
    orderEarnDetails,
  };

  return await postAdminLuxLoyaltyEarn(payload);
}
